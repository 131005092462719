import { createRouter, createWebHistory } from 'vue-router'
import { useClerk, useUser } from 'vue-clerk'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./components/MealPlan.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('./components/user/SignUpPage.vue'),
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import('./components/user/SignInPage.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/saved-meal-plans',
    name: 'SavedMealPlans',
    component: () => import('./components/SavedMealPlans.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/saved-meal-plan/:id',
    name: 'SavedMealPlanDetails',
    component: () => import('./components/SavedMealPlanDetails.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/admin',
    name: 'AdminUserList',
    component: () => import('./components/admin/AdminUserList.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/templates',
    name: 'AdminTemplates',
    component: () => import('./components/admin/AdminTemplates.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/templates/create',
    name: 'AdminTemplateCreate',
    component: () => import('./components/admin/AdminTemplateCreate.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/admin/templates/:id/edit',
    name: 'AdminTemplateEdit',
    component: () => import('./components/admin/AdminTemplateCreate.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/templates',
    name: 'BrowseTemplates',
    component: () => import('./components/templates/BrowseTemplates.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/templates/:id',
    name: 'TemplateDetails',
    component: () => import('./components/templates/TemplateDetails.vue'),
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const clerk = useClerk()
  const { user } = useUser()
  
  // Wait for Clerk to initialize if it hasn't already
  if (!clerk.loaded) {
    await new Promise(resolve => clerk.addOnLoaded(resolve))
  }

  const isSignedIn = !!clerk.session
  const isAdmin = isSignedIn && user.value?.publicMetadata?.role === 'admin'

  if (to.meta.requiresAuth && !isSignedIn) {
    // Redirect to SignIn page if the route requires auth and the user isn't signed in
    next({ name: 'SignIn' })
  } else if (to.meta.requiresAdmin && !isAdmin) {
    // Redirect to Home if the route requires admin role and the user isn't an admin
    next({ name: 'Home' })
  } else if (to.name === 'SignIn' && isSignedIn) {
    // Redirect to Home if the user is already signed in and trying to access SignIn page
    next({ name: 'Home' })
  } else {
    // Proceed as normal for other cases
    next()
  }
})

export default router
