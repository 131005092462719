<template>
  <Menubar :model="items">
    <template #item="{ item, props, hasSubmenu }">
      <router-link v-if="item.to" v-slot="{ href, navigate }" :to="item.to" custom>
        <a v-ripple :href="href" v-bind="props.action" @click="navigate">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </router-link>
      <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
      </a>
    </template>
    <template #end>
      <SignedIn>
        <UserButton />
      </SignedIn>
    </template>
  </Menubar>
</template>

<script setup>
import { ref, computed } from 'vue'
import { SignedIn, UserButton, useClerk, useUser } from 'vue-clerk'
import Menubar from 'primevue/menubar'
import Ripple from 'primevue/ripple'

const { user } = useUser()

const isAdmin = computed(() => {
  return user.value?.publicMetadata?.role === 'admin'
})

const items = computed(() => [
  {
    label: 'Meal Plans',
    items: [
      {
        label: 'Create New',
        to: '/',
        icon: 'pi pi-plus'
      },
      {
        label: 'Browse Templates',
        to: '/templates',
        icon: 'pi pi-list'
      },
      {
        label: 'Saved',
        to: '/saved-meal-plans',
        icon: 'pi pi-save'
      }
    ]
  },
  ...(isAdmin.value ? [{
    label: 'Admin',
    items: [
      {
        label: 'User Management',
        to: '/admin',
        icon: 'pi pi-users'
      },
      {
        label: 'Templates',
        to: '/admin/templates',
        icon: 'pi pi-file'
      }
    ]
  }] : [])
])
</script>

<style scoped>
:deep(.cl-userButtonBox) {
  height: 36px;
}

:deep(.cl-userButtonAvatar) {
  width: 36px;
  height: 36px;
}

/* Add this new style */
:deep(.admin-link) {
  pointer-events: auto !important;
}
</style>
