<template>
  <div class="shared-styles-wrapper">
    <!-- This div is just a wrapper and won't render any visible content -->
  </div>
</template>

<script>
export default {
  name: 'SharedStyles'
}
</script>

<style>
.button-container {
  justify-content: center;
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.recipe-pill {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #7fbfb2;
  color: white;
}

.recipe-pill:hover {
  background-color: #6aa99c;
}

.recipe-pill i {
  margin-right: 6px;
}

.regenerate-pill {
  background-color: #6c757d;
}

.regenerate-pill:hover {
  background-color: #5a6268;
}
</style>